export default[
  {
    path: '/home',
    name: '/home',
    component: () => import('@/views/dashboard/Index.vue'),
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
    {
      path: '/registrasi',
      name: 'registrasi',
      component: () => import('@/views/registrasi/Index.vue'), 
      meta: {
        layout: 'full',
        pageTitle: 'Registrasi Akun Jala Pusaka',
        breadcrumb: [ 
          {
            text: 'Rrgistrasi',
            active: true,
          },
        ],
      },
    }, {
      path: '/alur_pembayaran',
      name: 'alur_pembayaran',
      component: () => import('@/views/registrasi/alurPembayaran.vue'), 
      meta: {
        layout: 'full',
        pageTitle: 'Alur Pembayaran',
        breadcrumb: [ 
          {
            text: 'Rrgistrasi',
            active: true,
          },
        ],
      },
    }, 
    {
      path: '/registrasi_info',
      name: 'registrasi_info',
      component: () => import('@/views/registrasi/RegistrasiInfo.vue'), 
      meta: {
        layout: 'full', 
      },
    }, 
    {
        path: '/lupa_password',
        name: 'lupa_password',
        component: () => import('@/views/registrasi/LupaPassword.vue'),
        meta: {
            layout: 'full',
          pageTitle: 'Lupa Password Akun Jala Pusaka',
          breadcrumb: [ 
            {
              text: 'Lupa Password',
              active: true,
            },
          ],
        },
      }, 
]