export default [ 
    {
      path: '/tagihan_tervalidasi',
      name: '/tagihan_tervalidasi',
      component: () => import('@/views/laporan/tagihan_tervalidasi/index.vue'),
      meta: {
        pageTitle: 'Laporan',
        breadcrumb: [{
            text: 'Laporoan',
            to: '/kelompok_list'
          },
          {
            text: 'Tagihan Tervalidasi',
            to: ''
          },
  
        ],
      },
       
    },
    {
      path: '/rekap_tagihan',
      name: '/rekap_tagihan',
      component: () => import('@/views/laporan/rekap_tagihan/index.vue'),
      meta: {
        pageTitle: 'Laporan',
        navActiveLink: '/rekap_tagihan',
        breadcrumb: [{
            text: 'Laporan',
            to: '/rekap_tagihan'
          }, 
          {
            text: 'Rekap Taggihan',
            active: true
          },


        ],
      },
    }, 
    // {
    //   path: '/laporan_perkelompok_map/:id_kelompok',
    //   name: '/laporan_perkelompok_map',
    //   component: () => import('@/views/laporan/kelompok/map/index.vue'),
    //   meta: {
    //     pageTitle: 'Kelompok',
    //     navActiveLink: '/laporan_perkelompok_list',
    //     breadcrumb: [{
    //         text: 'Laporan Perkelompok',
    //         to: '/laporan_perkelompok_list'
    //       },
    //       {
    //         text: 'Map',
    //         to: '/laporan_perkelompok_list'
    //       },
    //       {
    //         text: 'Sebaran Presensi Anggota ',
    //         active: true
    //       },


    //     ],
    //   },
    // },
    
  ]