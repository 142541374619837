export default[ 
    
    {
      path: '/jensi_pembayaran',
      name: '/jensi_pembayaran',
      component: () => import('@/views/master/jensi_pembayaran/index.vue'),
      meta: {
        pageTitle: 'Setup',
        breadcrumb: [ 
          {
            text: 'Setup',
            active: false,
          },
          {
            text: 'Jenis Pembayaran',
            active: true,
          },
          
        ],
      },
    } ,
    {
        path: '/rekening_pembayaran',
        name: '/rekening_pembayaran',
        component: () => import('@/views/master/rekening_pembayaran/index.vue'),
        meta: {
          pageTitle: 'Rekening Pembayaran',
          breadcrumb: [ 
            {
              text: 'Setup',
              active: false,
            },
            {
              text: 'Rekening Pembayaran',
              active: true,
            },
            
          ],
        },
      } ,
      {
          path: '/periode_pembayaran',
          name: '/periode_pembayaran',
          component: () => import('@/views/master/periode_pembayaran/index.vue'),
          meta: {
            pageTitle: 'Rekening Pembayaran',
            breadcrumb: [ 
              {
                text: 'Setup',
                active: false,
              },
              {
                text: 'Periode Pembayaran',
                active: true,
              },
              
            ],
          },
        } 
]