export default [
//mahasiswa
  {
    path: '/pembayaran',
    name: '/pembayaran',
    component: () => import('@/views/mahasiswa/paymeny/index.vue'),
    meta: {
      pageTitle: 'Paymeny',
      breadcrumb: [{
          text: 'Paymeny',
          to: '/pembayaran'
        },{
          text: 'Jenis Pembayaran Aktif',
          to: '/pembayaran'
        },


      ],
    }, 
  }, 
  {
    path: '/histori_pembayaran_mhs',
    name: '/histori_pembayaran_mhs',
    component: () => import('@/views/mahasiswa/histori_pembayaran/index.vue'),
    meta: {
      pageTitle: 'Pembayaran',
      breadcrumb: [{
          text: 'Pembatyaran',
          to: '/histori_pembayaran_mhs'
        },{
          text: 'histori_pembayaran',
          to: '/histori_pembayaran_mhs'
        }, 
      ],
    }, 
  }, 
  //route tendik
  {
    path: '/tagihan_mahasiswa',
    name: '/tagihan_mahasiswa',
    component: () => import('@/views/modul/tagihan_mahasiswa/index.vue'),
    meta: {
      pageTitle: 'Tagihan',
      navActiveLink: '/tagihan_mahasiswa',
      breadcrumb: [{
          text: 'Tagihan',
          to: '/tagihan_mahasiswa'
        },
        {
          text: 'Tagihan Mahasiswa',
          active: true
        }, 
      ],
    },
  }, 
  {
    path: '/validasi_pembayaran',
    name: '/validasi_pembayaran',
    component: () => import('@/views/modul/validasi_pembayaran/index.vue'),
    meta: {
      pageTitle: 'Tagihan',
      navActiveLink: '/validasi_pembayaran',
      breadcrumb: [{
          text: 'Tagihan',
          to: '/validasi_pembayaran'
        },
        {
          text: 'Validasi Pembayaran',
          active: true
        }, 
      ],
    },
  }, 
  {
    path: '/histori_pembayaran',
    name: '/histori_pembayaran',
    component: () => import('@/views/modul/histori_pembayaran/index.vue'),
    meta: {
      pageTitle: 'Tagihan',
      navActiveLink: '/histori_pembayaran',
      breadcrumb: [{
          text: 'Tagihan',
          to: '/histori_pembayaran'
        },
        {
          text: 'Histori Pembayaran',
          active: true
        }, 
      ],
    },
  },
  {
    path: '/cek_status_pembayaran',
    name: '/cek_status_pembayaran',
    component: () => import('@/views/modul/cek_status_pembayaran/index.vue'),
    meta: {
      pageTitle: 'Tagihan',
      navActiveLink: '/cek_status_pembayaran',
      breadcrumb: [{
          text: 'Tagihan',
          to: '/cek_status_pembayaran'
        },
        {
          text: 'Cek Status Pembayaran',
          active: true
        }, 
      ],
    },
  }, 
]